<template>
    <div class="filter-shell">
        <div class="filter-title">{{title}}</div>
        <span class="colon">：</span>

        <div class="filter-condition">
            <div class="first-level">
                <div class="first-level-box" v-for="(items,index) in data" :key="items.id">
                    <div class="first-level-title" :ref='`firstLv`+index'
                        @mouseover='mouseover(index,items)' @mouseout='mouseout(items,index)' @click="go(items.id)">
                        {{items.name}}<i v-if="items.child"
                            :class="isShow === index ? 'el-icon-arrow-down' : 'el-icon-arrow-up' "></i>
                    </div>
                    <template v-if="items.child">
                        <div v-show="isShow === index">
                            <div class="second-level" :ref='`secondLv`+index' @mouseover='mouseover(index,items)'
                                @mouseout='mouseout(items,index)'>
                                <div class="second-level-box" v-for="(item,ind) in items.child" :key="ind"
                                    @click='go(item.id)'>{{item.name}}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: [
            'title',
            'data'
        ],
        data() {
            return {
                isShow: false,
                level: [],
            }
        },
        methods: {
            // 点击时
            click(item, index) {
                if (item.child) return

                // this.isShow = index
                // this.adjustPosition(index)
            },

            // 鼠标移入时
            mouseover(index, item) {
 
                // this.level.push(item.id)
                if (!item.child) return
                this.isShow = index
                this.adjustPosition(index)
                this.$refs['firstLv' + index][0].style.background = '#ebf8ff'
            },

            // 鼠标移出时
            mouseout(item,index) {
                if (!item.child) return
                this.$refs['firstLv' + index][0].style.background = ''
                this.isShow = false
            },

            // 调整二级菜单出现的位置
            adjustPosition(index) {
                let firstWidth = this.$refs['firstLv' + 0][0].getBoundingClientRect().left,
                    width = this.$refs['firstLv' + index][0].getBoundingClientRect().left;
                this.$refs[`secondLv` + index][0].style['margin-left'] = firstWidth - width + 'px'

            },

            go(index) {
                this.$emit('on-go', index);
            }
        },
    }
</script>

<style lang="less" scoped>
    .filter-shell {
        display: flex;

        .filter-title {
            width: 64px;
            flex: none;
            line-height: 30px;
            text-align: justify;
            text-align-last: justify;
        }

        .colon {
            line-height: 30px;
        }

        .filter-condition {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            align-items: center;

            .first-level {
                display: flex;
                flex-wrap: wrap;

                .first-level-box {
                    margin: 0 20px 20px 0;
                    position: relative;

                    height: 30px;
                    line-height: 30px;

                    .first-level-title {
                        cursor: pointer;
                        padding: 0 10px;
                    }

                    .second-level {
                        width: 1000px;
                        background: #ebf8ff;
                        margin: 0px 0 0 -20px;
                        display: flex;
                        flex-wrap: wrap;
                        position: absolute;
                        z-index: 99;

                        .second-level-box {
                            margin: 10px 20px;
                            cursor: pointer;
                            &:hover {
                                color: #008cf4;
                            }
                        }
                    }

                }
            }
        }
    }
</style>
